const imageBasePath = '/dx_guests_app/images/banner';

export const images = {
  mobile: `${imageBasePath}/banner-home-mast-mobile.jpg`,
  tablet: `${imageBasePath}/banner-home-mast-tablet.jpg`,
  desktop: `${imageBasePath}/banner-home-mast-desktop.jpg`,
};

export const perks = {
  mobile: `${imageBasePath}/banner-home-perks-mobile.png`,
  tablet: `${imageBasePath}/banner-home-perks-tablet.png`,
  desktop: `${imageBasePath}/banner-home-perks-desktop.png`,
};

// Order matters
export const icons = [
  'low-price-white.svg',
  'wifi-white.svg',
  'hhonors-points-white.svg',
  'phone-checkmark-white.svg',
  'exclusive-offers-white.svg',
];
