/* eslint-disable import/no-cycle */
import { useState } from 'react';
import type { MouseEvent } from 'react';
import { Link } from '@dx-ui/osc-link';
import cx from 'classnames';
import { useMediaQuery } from 'usehooks-ts';
import { formatLabel } from '../../utils/helpers/metrics';
import { doesUrlHaveQueryParameters } from '../../utils/routeHelpers';
import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import { useMetrics } from '../../hooks';
import type { BrandLink, BrandMosaicGridItem } from '@dx-ui/gql-types';

export type Props = {
  columnIndex?: number;
  itemIndex?: number;
  ctaClassName?: string;
  pageType?: string;
  language?: SupportedLanguage;
  id?: string;
} & BrandMosaicGridItem;

const RewardsTile = ({
  label,
  id,
  image,
  itemIndex = 0,
  columnIndex = 0,
  headline = '',
  shortDescription = '',
  link,
  ctaClassName,
  pageType,
}: Props) => {
  const { metrics } = useMetrics();

  const [isExpanded, setIsExpanded] = useState(false);

  const isLargeAndUp = useMediaQuery('(min-width: 769px)');
  const isMedAndUp = useMediaQuery('(min-width: 650px)');

  const tileLink = link || ({} as BrandLink);

  const tileLinkEventAction = (tileLink && !!tileLink.label && formatLabel(tileLink.label)) || '';

  const tileLinkPageDetail =
    (tileLink && !!tileLink.label && `${formatLabel(tileLink.label)}initiated`) || '';

  const tileId = id || `item${itemIndex}Column${columnIndex}`;

  const trackClick = () => {
    if (metrics && label) {
      const pageDetail = formatLabel(label);
      if (pageDetail) {
        const eventAction = `${pageDetail}initiated`;
        metrics.setEventAction([{ eventAction }]);
        metrics.trackEvent(eventAction);
        metrics.setPageDetails([{ pageDetail }]);
        metrics.trackPageDetails();
      }
    }
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    // Only track if the tile will be opening
    if (!isExpanded) {
      trackClick();
    }
    setIsExpanded((prevState) => !prevState);
  };

  const getBackgroundImageUrl = () => {
    if (isMedAndUp) {
      return image?.variants?.[1]?.url;
    }
    if (isLargeAndUp) {
      return image?.variants?.[0]?.url;
    }
    return image?.variants?.[2]?.url;
  };

  return (
    <div
      className={cx(
        `max-[649px]:w-72 max-[649px]:h-96 max-[649px]:my-0 max-[649px]:mx-auto z-2 hover:z-10 relative w-full bg-cover pt-0 bg-no-repeat bg-right-top`,
        {
          'sm:pt-[100%]': columnIndex === 0,
          'sm:pt-[705px]': columnIndex === 1,
          'sm:pt-[376px]':
            (columnIndex === 2 && itemIndex === 1) || (columnIndex === 3 && itemIndex === 2),
          'sm:pt-[208px]':
            (columnIndex === 2 && itemIndex === 2) || (columnIndex === 3 && itemIndex === 1),
          'md:pt-[750px]': columnIndex === 1,
          'md:pt-[131.6%]':
            (columnIndex === 2 && itemIndex === 1) || (columnIndex === 3 && itemIndex === 2),
          'md:pt-[75.6%]':
            (columnIndex === 2 && itemIndex === 2) || (columnIndex === 3 && itemIndex === 1),
          'min-[1440px]:pt-[880px]': columnIndex === 1,
          'min-[1440px]:pt-[131.6%]':
            (columnIndex === 2 && itemIndex === 1) || (columnIndex === 3 && itemIndex === 2),
          'min-[1440px]:pt-[75.6%]':
            (columnIndex === 2 && itemIndex === 2) || (columnIndex === 3 && itemIndex === 1),
          'min-h-[670px]': columnIndex === 1 && itemIndex === 1,
        }
      )}
      style={{ backgroundImage: `url(${getBackgroundImageUrl()})` }}
      data-index={itemIndex}
    >
      <div className="absolute inset-0" data-testid={`${tileId}RewardBlock`}>
        <div
          id={`${tileId}Tile`}
          className={cx(
            'overflow-hidden absolute min-h-20 pt-6 px-6 pb-0 w-full top-auto -right-7 -bottom-8 sm:-top-11 sm:-right-7',
            {
              'bg-bg-inverse': pageType,
              'bg-opacity-90': pageType && isExpanded,
              'hover:bg-opacity-90': pageType && !isExpanded,
              'min-w-56': columnIndex === 1,
              'sm:w-2/4': columnIndex === 1,
              'sm:right-9': columnIndex === 1,
              'sm:bottom-11': columnIndex === 1,
              'sm:top-auto': columnIndex === 1 || columnIndex === 2,
              'sm:-right-5': columnIndex === 2,
              'sm:-bottom-11': columnIndex === 2,
              'sm:bottom-auto': columnIndex === 3,
            }
          )}
        >
          <h3
            className={cx(
              'pr-7 font-sans heading-lg sm:heading-xl xl:heading-2xl text-text-inverse',
              { 'pr-0': isExpanded || !!headline }
            )}
          >
            <label>
              {label}
              <button
                type="button"
                className={cx(
                  'bg-bg-inverse border-0 bottom-2.5 absolute size-11 ltr:right-2.5 rtl:left-2.5 z-[2] before:top-2/4 before:pt-0 before:pl-0 before:pb-px before:pr-5 before:bg-transparent before:border-t-white before:transition-transform after:rotate-0 after:transition-transform after:bg-transparent after:border-l-white before:absolute before:border-t after:border-l before:left-[calc(50%_-_9px)] after:absolute after:top-[calc(50%_-_9px)] after:left-[calc(50%_+_1px) after:pt-0 after:pr-px after:pb-5 after:pl-0',
                  { 'after:rotate-90': isExpanded }
                )}
                aria-label={label || undefined}
                onClick={handleClick}
                aria-expanded={isExpanded}
                aria-controls={`${tileId}Tile`}
                data-testid={`${tileId}Button`}
              />
            </label>
          </h3>
          <p
            className={cx('pr-7 text-base xl:text-xl font-bold font-sans mx-0 mt-2 mb-6', {
              'text-bg': pageType,
              'pr-0': isExpanded,
            })}
          >
            {headline}
          </p>
          <div
            className={cx('overflow-hidden transition-[max-height] duration-200 ease-linear', {
              'max-h-72': isExpanded,
              'max-h-0': !isExpanded,
            })}
            aria-expanded={isExpanded}
            aria-hidden={!isExpanded}
            data-testid={`${tileId}Section`}
          >
            <p
              className={cx(
                'pb-8 font-sans text-base font-normal sm:text-sm md:pb-24 lg:pb-8 xl:pb-20 2xl:text-base',
                { 'text-text-inverse': pageType }
              )}
            >
              {shortDescription}
            </p>
            {tileLink?.url ? (
              <Link
                url={
                  tileLink?.campaignId
                    ? tileLink.url
                    : doesUrlHaveQueryParameters(tileLink.url)
                    ? `${tileLink.url}&eventAction=${tileLinkEventAction}&pageDetail=${tileLinkPageDetail}`
                    : `${tileLink.url}?eventAction=${tileLinkEventAction}&pageDetail=${tileLinkPageDetail}`
                }
                tabIndex={!isExpanded ? -1 : 0}
                className={cx(
                  'btn btn-xl md:btn-xl xl:btn-2xl mb-2 max-w-full whitespace-pre-wrap break-words text-center',
                  {
                    'btn-tertiary-outline': !ctaClassName,
                    [ctaClassName as string]: !!ctaClassName,
                  }
                )}
                role="button"
                target={tileLink.isNewWindow ? '_blank' : undefined}
                cidParams={{
                  campaignId: tileLink?.campaignId as string,
                  componentName: 'RewardsTile',
                }}
              >
                {tileLink.label}
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardsTile;
