import { useAuth } from '@dx-ui/framework-auth-provider';
import { Link } from '@dx-ui/osc-link';
import cx from 'classnames';
import type { InferGetServerSidePropsType } from 'next';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import { makeServerQueryClient } from '@dx-ui/framework-react-query';

import { getServerSideShopFormData } from '@dx-ui/osc-shop-form';
import BannerContainer from '../../components/BannerContainer/BannerContainer';
import { Grid4xCarousel } from '../../components/Grid4xCarousel/Grid4xCarousel';
import { Head } from '../../components/Head/Head';
import { JoinAndFindHotel } from '../../components/JoinAndFindHotel/JoinAndFindHotel';
import RewardsGrid from '../../components/RewardsGrid/RewardsGrid';
import RotatingTiles from '../../components/RotatingTiles/RotatingTiles';
import { REACT_QUERY_CONFIG } from '../../config/react-query';
import { useMetrics } from '../../hooks/useMetrics';
import { serverSideBrand_NonMemberQuery } from '../../queries/generated/react-query';
import type { Images } from '../../types/Images';
import type {
  FourXGridFragment,
  FullWidthImageFragment,
  MosaicGridFragment,
  RotatingTilesFragment,
} from '../../types/generated/types';
import { auth } from '../../utils/auth';
import {
  ENABLE_ALERT_BANNER,
  ENABLE_COVID_POLICY_UPDATE,
} from '../../utils/constants/featureFlags';
import { GLOBAL_RESOURCE_BUNDLES } from '../../utils/constants/global-resource-bundles';
import { images } from '../../utils/constants/home';
import {
  isFeatureEnabled,
  useServerSideFeatureToggles as UseServerSideFeatureToggles,
} from '../../utils/featureToggles';
import { show404NonTransPage } from '../../utils/helpers/show404NonTransPage';
import type { BrandImageVariant } from '@dx-ui/gql-types';
import { serverSideProps } from '../../utils/helpers/serverSideProps';

const getBanner = (fullWidthImage: FullWidthImageFragment['fullWidthImage'][0] | undefined) => ({
  link: { ...fullWidthImage?.link },
  images:
    fullWidthImage?.imageCompound?.image?.variants.reduce(
      (acc: Images, { size, url }: BrandImageVariant) => {
        switch (size) {
          case 'xs':
            acc.mobile = url as string;
            break;
          case 'sm':
            acc.tablet = url as string;
            break;
          case 'md':
            acc.desktop = url as string;
            break;
          default:
            acc.desktop = '';
            break;
        }
        return acc;
      },
      images
    ) || images,
});

export const NonMemberHome = ({
  hasCovidPolicy,
  nonMemberData: data,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { locale } = useRouter();
  const language = (locale as SupportedLanguage) || 'en';
  const { isAuthenticated } = useAuth();
  const { metrics } = useMetrics();
  const [t] = useTranslation('honors-home');

  const fullWidthImage = data?.brand?.page?.fullWidthImage?.[0];
  const banner = getBanner(fullWidthImage);
  const suppressOffers = language !== 'en';

  let ctaURL;
  try {
    ctaURL = new URL(fullWidthImage?.link?.url || '');
    const ctaURLSearchParams = new URLSearchParams(
      `${ctaURL.search}${ctaURL.search ? '&' : '?'}eventAction=joinhiltonInitiate&pageDetail=${
        banner?.link?.label
      }`
    );
    ctaURL.search = ctaURLSearchParams.toString();
  } catch {
    ctaURL = '';
  }

  return (
    <>
      <Head
        title={t('title')}
        keywords={t('metaData.keywords')}
        description={t('metaData.description')}
      />
      <div>
        {!isAuthenticated ? <JoinAndFindHotel elementId="homeBanner" /> : null}
        {hasCovidPolicy ? (
          <section className="container text-center" data-testid="policyUpdate">
            {fullWidthImage?.headline ? (
              <h2 className="font-headline text-hilton-alt mb-2 text-4xl xl:mb-4">
                {fullWidthImage?.headline}
              </h2>
            ) : null}
            {fullWidthImage?.shortDescription ? (
              <span className="mb-2 inline-block max-w-xl font-sans text-xl font-bold xl:max-w-full xl:text-2xl">
                {fullWidthImage?.shortDescription}
              </span>
            ) : null}
          </section>
        ) : null}
        <div id="homeBanner">
          <BannerContainer className="z-2 relative !h-96 shadow lg:py-72" images={banner.images}>
            <div className="relative mx-auto my-0 h-80 w-full self-start md:-left-1 md:w-[468px] md:self-center">
              {language === 'en' ? (
                <div
                  className={cx(
                    'border-text-alt bg-text absolute -top-2.5 z-0 h-full w-full overflow-hidden border border-solid bg-opacity-80 bg-cover bg-no-repeat md:rounded-lg'
                  )}
                >
                  <div
                    className={cx(
                      'absolute z-0 h-full w-full overflow-hidden  backdrop-blur-lg',
                      'after:-z-1 after:rounded-none'
                    )}
                  >
                    <span
                      className="font-headline text-text-inverse z-1 absolute left-[calc(50%-158px)] top-12 text-4xl font-semibold leading-[3.5rem] tracking-tighter md:left-20"
                      data-e2e="homeBannerHeadline"
                    >
                      {t('weBe')}
                    </span>
                    <span
                      className="font-headline-brand text-hilton-alt absolute left-[calc(50%-125px)] top-1 text-[150px] tracking-tight md:left-28"
                      data-e2e="homeBannerSubHeadline"
                    >
                      {t('honored')}
                    </span>
                    <span
                      className="font-headline text-text-inverse z-1 absolute left-[calc(50%-60px)] top-32 text-4xl font-semibold leading-[3.5rem] md:left-44"
                      data-e2e="homeBannerSubHeadline2"
                    >
                      {t('toHave')}
                    </span>
                    <span
                      className="text-text-inverse absolute bottom-16 left-px right-0 text-center font-sans text-2xl font-bold"
                      data-e2e="homeBannerContextText"
                    >
                      {t('itsFree')}
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  className={cx(
                    'border-text-alt bg-text absolute -top-2.5 z-0 h-full w-full overflow-hidden border border-solid bg-opacity-80 bg-cover bg-no-repeat md:rounded-lg'
                  )}
                >
                  <div
                    className={cx(
                      'absolute z-0 h-full w-full overflow-hidden backdrop-blur-lg',
                      'after:-z-1 after:rounded-none'
                    )}
                  >
                    <div
                      className={cx(
                        'font-headline-brand text-hilton-alt text-center tracking-tighter',
                        {
                          'mt-12': ['zh-hans', 'zh-hant'].includes(language),
                          'mt-8': ['ja', 'ru'].includes(language),
                          'mt-2': ['ro'].includes(language),
                          'mt-0': !['zh-hans', 'zh-hant', 'ro', 'ja', 'ru'].includes(language),
                        },
                        {
                          'text-6xl md:text-7xl': ['zh-hans', 'zh-hant'].includes(language),
                          'text-8xl': ['ja', 'ru', 'th'].includes(language),
                          'text-9xl': language.includes('ro'),
                          'text-[132px]': ['bg'].includes(language),
                          'text-[150px]': !['th', 'bg', 'ja', 'ru', 'zh-hans', 'zh-hant'].includes(
                            language
                          ),
                        }
                      )}
                      data-e2e="homeBannerSubHeadline"
                    >
                      {t('welcome')}
                    </div>
                    <span
                      className={cx(
                        'text-text-inverse font-headline z-1 absolute text-center font-normal',
                        {
                          'top-28': language === 'th',
                          'top-36': language === 'bg',
                          'top-48 w-[calc(100%-24px)]': language === 'ar',
                          'top-32': !['th', 'ar', 'bg'].includes(language),
                        },
                        {
                          'tracking-wide md:tracking-normal': ['fi'].includes(language),
                          'tracking-normal': !['fi'].includes(language),
                        },
                        {
                          'text-2xl': ['ru', 'bg'].includes(language),
                          'text-3xl md:text-4xl': !['ru', 'bg'].includes(language),
                        },
                        {
                          'mx-20': language === 'th',
                          'mx-3': ['nl', 'ar', 'de', 'sv'].includes(language),
                          'mx-1 md:mx-3': ['zh-hans', 'zh-hant'].includes(language),
                          'mx-5': ['nl', 'fr'].includes(language),
                          'mx-7': ['pt', 'ro'].includes(language),
                          'my-3 mx-5 md:my-6 md:mx-8': language === 'ko',
                          'mx-18': ['fi', 'it'].includes(language),
                          'mx-12': language === 'ru',
                          'm-0': ![
                            'nl',
                            'ar',
                            'de',
                            'sv',
                            'zh-hans',
                            'zh-hant',
                            'pt',
                            'ro',
                            'ko',
                            'fi',
                            'it',
                            'ru',
                            'th',
                          ].includes(language),
                        }
                      )}
                      data-e2e="homeBannerSubHeadline2"
                    >
                      {t('wouldBeHonored')}
                    </span>
                    <div
                      className={cx(
                        'text-text-inverse text-center font-sans text-2xl font-normal tracking-tight',
                        {
                          'mt-24 md:mt-20': ['zh-hans', 'zh-hant', 'ro'].includes(language),
                          'mt-20 md:mt-24': ['ja', 'th'].includes(language),
                          'mt-24 md:mt-28': ['th'].includes(language),
                          'mt-20 md:mt-16': ['ru'].includes(language),
                          'mt-3 md:mt-5': ['ar', 'de', 'sv'].includes(language),
                          'mt-16 md:mt-14': ['bg'].includes(language),
                          'mt-5': ![
                            'zh-hans',
                            'zh-hant',
                            'bg',
                            'ru',
                            'ar',
                            'th',
                            'ja',
                            'de',
                            'sv',
                            'ro',
                          ].includes(language),
                        }
                      )}
                      data-e2e="homeBannerContextText"
                    >
                      {t('itsFree')}
                    </div>
                  </div>
                </div>
              )}
              {banner?.link?.url && banner?.link?.label ? (
                <Link
                  adaDescription={banner?.link?.label}
                  url={banner?.link?.url}
                  isNewWindow={banner?.link?.isNewWindow || false}
                  role="button"
                  cidParams={{
                    campaignId: banner?.link?.campaignId as string,
                    componentName: 'NonMemberHome',
                  }}
                  className="btn btn-2xl btn-secondary absolute left-1/2 top-72 -mt-2 w-11/12 -translate-x-1/2 whitespace-normal text-center text-2xl no-underline sm:w-auto sm:whitespace-nowrap"
                >
                  {banner?.link?.label}
                </Link>
              ) : null}
            </div>
          </BannerContainer>
        </div>
        {hasRotatingTiles(data?.brand?.page) && data?.brand?.page?.rotatingTiles?.[0] ? (
          // @ts-expect-error RotatingTiles will be deleted NHCBP-4189
          <RotatingTiles
            {...data?.brand.page.rotatingTiles[0]}
            metrics={metrics}
            language={language}
          />
        ) : null}
        {hasMosaicGrid(data?.brand?.page) && data?.brand?.page?.mosaicGrid?.[0] ? (
          <RewardsGrid
            {...data?.brand.page.mosaicGrid[0]}
            language={language}
            metrics={metrics}
            shouldSuppressOffers={suppressOffers}
            ctaClassName="text-text-inverse border-text-inverse hover:bg-secondary"
            pageType="home"
          />
        ) : null}
        {!suppressOffers && hasFourXGrid(data?.brand?.page) && data?.brand?.page?.fourXGrid?.[0] ? (
          <Grid4xCarousel {...data?.brand.page.fourXGrid[0]} metrics={metrics} />
        ) : null}
      </div>
    </>
  );
};

function hasRotatingTiles(obj: unknown): obj is RotatingTilesFragment {
  return typeof obj === 'object' && obj !== null && 'rotatingTiles' in obj;
}

function hasMosaicGrid(obj: unknown): obj is MosaicGridFragment {
  return typeof obj === 'object' && obj !== null && 'mosaicGrid' in obj;
}

function hasFourXGrid(obj: unknown): obj is FourXGridFragment {
  return typeof obj === 'object' && obj !== null && 'fourXGrid' in obj;
}

const trackingData = {
  pageView: 'honorsPageView' as const,
  pageData: {
    pageName: 'home',
    pageType: 'home',
    subSection: 'brand',
    subSubSection: 'home',
    siteType: 'Homepage',
  },
};

export const getServerSideProps = (async (context) => {
  const { req, res, locale } = context;
  const authClient = auth(req as unknown as Request);
  const queryClient = makeServerQueryClient({
    ...REACT_QUERY_CONFIG,
    serverResponse: res,
    authClient,
  });

  const show404 = await show404NonTransPage({ url: req.path, language: locale, queryClient });
  if (show404) return { notFound: true };

  const nonMemberData =
    locale !== 'en'
      ? await serverSideBrand_NonMemberQuery(queryClient, {
          brandCode: 'HH',
          language: locale,
          shouldIncludeFourXGrid: true,
        })
      : await serverSideBrand_NonMemberQuery(queryClient, {
          brandCode: 'HH',
          language: locale,
          shouldIncludeFourXGrid: true,
        });

  const featureToggles = await UseServerSideFeatureToggles(queryClient, [
    ENABLE_ALERT_BANNER,
    ENABLE_COVID_POLICY_UPDATE,
  ]);
  const hasAlert = isFeatureEnabled(featureToggles, ENABLE_ALERT_BANNER);
  const hasCovidPolicy = isFeatureEnabled(featureToggles, ENABLE_COVID_POLICY_UPDATE);

  await getServerSideShopFormData(queryClient).catch((e) => {
    console.error('Error fetching serverSideShopFormData', e); //eslint-disable-line no-console
  });

  return serverSideProps({
    authClient,
    context,
    queryClient,
    pageProps: {
      layout: {
        isHomepage: true,
        hasFixedHeader: true,
        hasAlert,
      },
      featureToggles,
      hasCovidPolicy,
      userAgent: req.headers?.['user-agent'],
      metrics: { trackingData },
      nonMemberData,
    },
    namespaces: [...GLOBAL_RESOURCE_BUNDLES, 'honors-home'],
  });
}) satisfies GetServerSideProps;

export default NonMemberHome;
