/* eslint-disable import/no-cycle */
import * as React from 'react';
import Swiper from 'react-id-swiper/lib/custom';

import type { Props as TileProps } from '../RewardsTile/RewardsTile';
import RewardsTile from '../RewardsTile/RewardsTile';
import { isRtlLanguage } from '../../utils/helper';
import type { Props as InputProps } from './RewardsGrid';
import Styled from './RewardsGrid.style';
import type { State, Text } from './RewardsMobileGrid';
import MobileGrid from './RewardsMobileGrid';
import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import { Icon } from '@dx-ui/osc-icon';

type Props = {
  text: Text;
  shouldSuppressOffers: boolean;
  view?: string;
} & InputProps;

class RewardsGrid extends React.Component<Props, State> {
  public state = {
    isNextButtonVisible: true,
    isPrevButtonVisible: false,
  };

  private swiper = null;

  private getSwiperViewMedium = () => {
    const isRtl = isRtlLanguage(this.props.language as SupportedLanguage);
    const options = {
      containerClass: 'rewards-slider-container',
      grabCursor: true,
      navigation: {
        nextEl: '#rewards-button-next',
        prevEl: '#rewards-button-prev',
      },
      on: {
        reachBeginning: () => this.swipeHandler(true, false),
        reachEnd: () => this.swipeHandler(false, true),
        slideChange: () => this.swipeHandler(false, false),
      },
      pagination: {
        clickable: true,
        el: '#rewards-pagination',
        type: 'fraction',
        renderFraction: (currentClass: string, totalClass: string) =>
          `<span class="${currentClass}"></span> ${this.props.text.of} <span class="${totalClass}"></span>`,
      },
      renderNextButton: () => (
        <Styled.LeftArrow
          type="button"
          isVisible={this.state.isPrevButtonVisible}
          id="rewards-button-prev"
          language={this.props.language}
        >
          <span>{this.props.text.more}</span>
          <Icon name={isRtl ? 'arrow-right' : 'arrow-left'} />
        </Styled.LeftArrow>
      ),
      renderPagination: () => <Styled.Pagination id="rewards-pagination" />,
      renderPrevButton: () => (
        <Styled.RightArrow
          type="button"
          isVisible={this.state.isNextButtonVisible}
          id="rewards-button-next"
          language={this.props.language}
        >
          <span>{this.props.text.more}</span>
          <Icon name={isRtl ? 'arrow-left' : 'arrow-right'} />
        </Styled.RightArrow>
      ),
      slidesPerView: 'auto',
      spaceBetween: 0,
    };

    return (
      <Swiper
        {...options}
        ref={(node: HTMLElement & { swiper: typeof Swiper }) =>
          node ? (this.swiper = node.swiper) : (this.swiper = null)
        } // eslint-disable-line no-return-assign
      >
        {this.getColumns(this.props.items, 'medium')}
      </Swiper>
    );
  };

  private swipeHandler = (isBeginning: boolean, isEnd: boolean) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const beginning = !!this.swiper?.isBeginning;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const end = !!this.swiper?.isEnd;
    if (isBeginning) {
      this.setState({ isNextButtonVisible: true, isPrevButtonVisible: false });
    } else if (isEnd) {
      this.setState({ isNextButtonVisible: false, isPrevButtonVisible: true });
    } else if (!beginning && !end) {
      this.setState({ isNextButtonVisible: true, isPrevButtonVisible: true });
    }
  };

  private getColumns = (items: TileProps[], view?: string): Array<React.ReactElement<Element>> => [
    <Styled.Column key="rewardsColumnOne">
      <RewardsTile
        {...items[0]}
        columnIndex={1}
        itemIndex={1}
        language={this.props.language}
        ctaClassName={this.props.ctaClassName}
        pageType={this.props?.pageType}
        {...(view ? { id: 'mediumItem1Column1' } : {})}
      />
    </Styled.Column>,
    <Styled.Column key="rewardsColumnTwo">
      <Styled.Row>
        <Styled.Column24>
          <Styled.Row62>
            <RewardsTile
              {...items[1]}
              columnIndex={2}
              itemIndex={1}
              language={this.props.language}
              ctaClassName={this.props.ctaClassName}
              pageType={this.props?.pageType}
              {...(view ? { id: 'mediumItem1Column2' } : {})}
            />
          </Styled.Row62>
          <Styled.Row38>
            <RewardsTile
              {...items[2]}
              columnIndex={2}
              itemIndex={2}
              language={this.props.language}
              ctaClassName={this.props.ctaClassName}
              pageType={this.props?.pageType}
              {...(view ? { id: 'mediumItem2Column2' } : {})}
            />
          </Styled.Row38>
        </Styled.Column24>
        <Styled.Column24>
          <Styled.Row38>
            <RewardsTile
              {...items[3]}
              columnIndex={3}
              itemIndex={1}
              language={this.props.language}
              ctaClassName={this.props.ctaClassName}
              pageType={this.props?.pageType}
              {...(view ? { id: 'mediumItem1Column3' } : {})}
            />
          </Styled.Row38>
          <Styled.Row62>
            <RewardsTile
              {...items[4]}
              columnIndex={3}
              itemIndex={2}
              language={this.props.language}
              ctaClassName={this.props.ctaClassName}
              pageType={this.props?.pageType}
              {...(view ? { id: 'mediumItem2Column3' } : {})}
            />
          </Styled.Row62>
        </Styled.Column24>
      </Styled.Row>
    </Styled.Column>,
  ];

  public render() {
    // todo: Remove MobileGrid once we start tailwind work and find a solution for device detection for responsive design
    return (
      <Styled.Wrapper suppressOffers={this.props.shouldSuppressOffers} data-testid="rewardsBlock">
        <Styled.Header className="font-headline font-semibold">{this.props.headline}</Styled.Header>
        {this.props.description && <Styled.SubHeader>{this.props.description}</Styled.SubHeader>}
        <div className="hidden lg:block">
          <Styled.Row>{this.getColumns(this.props.items)}</Styled.Row>
        </div>
        <div className="hidden sm:block lg:hidden">
          <Styled.Row>{this.getSwiperViewMedium()}</Styled.Row>
        </div>
        <div className="sm:hidden">
          <Styled.Row>
            <MobileGrid {...this.props} />
          </Styled.Row>
        </div>
      </Styled.Wrapper>
    );
  }
}

export default RewardsGrid;
